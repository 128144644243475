import React from "react";
import Navbar from "../components/Navbar";
// import HeaderSlide from "../components/HeaderSlide";
import Footer from "../components/Footer";
import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import Announcement from "../components/Announcement";
import CategoryList from "../components/CategoryList";

function CategoryPg() {
  // const { currentUser } = useContext(AuthContext);
  return (
    <Box sx={{ width: "100%" }}>
      <Stack spacing={5} sx={{ alignItems: "center" }}>
        <Announcement />
        <Navbar />
        <CategoryList />
        {/* <SubmitForm /> */}
        <Footer />
      </Stack>
    </Box>
  );
}

export default CategoryPg;
