import "./App.css";
import { ThemeProvider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { theme } from "./theme";
import Home from "./pages/Home";
import Cart from "./pages/CartPg";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Order from "./pages/Order";
import ProductScreenPg from "./pages/ProductScreenPg";
import Blog from "./pages/Blog";
import { Routes, Route } from "react-router-dom";
import SingleCategoryPg from "./pages/SingleCategoryPg";
import { useContext, useEffect, useState } from "react";
import { Context } from "./contexAPI/stateProvider";
import AboutUsPg from "./pages/AboutUsPg";
import ContactUsPg from "./pages/ContactUsPg";
import { proList } from "./dommyData/proData";
import CategoryPg from "./pages/CategoryPg";
import ProductListPg from "./pages/ProductListPg";
import RingLoader from "react-spinners/RingLoader";

function App() {
  const { orders, cart, user, isLoading, isOpenFormList, userDetail } =
    useContext(Context);
  console.log(cart);
  console.log(user);
  console.log(isLoading);
  console.log(orders);
  console.log(isOpenFormList);
  console.log(userDetail);

  // useEffect to fetch the Products
  useEffect(() => {
    const fetchProducts = () => {
      try {
        const res = proList;

        console.log(res);
      } catch (error) {
        console.log(error);
      }
    };

    fetchProducts();
  }, []);
  // console.log(res);

  // useEffect for react loading
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            marginTop: "300px",
          }}
        >
          <RingLoader
            color={"blue"}
            loading={loading}
            // cssOverride={override}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          <Typography>The Web Page is Loading.....</Typography>
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: "#faf5f8",
            // width: "100%",
            // overflowX: "scroll",
            boxSizing: "border-box",
          }}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/AboutUs" element={<AboutUsPg />} />
            <Route path="/Categories" element={<CategoryPg />} />
            <Route path="/Products" element={<ProductListPg />} />
            <Route path="/Categories/:id" element={<SingleCategoryPg />} />
            <Route path="/Product/:id" element={<ProductScreenPg />} />
            <Route path="/Cart" element={<Cart />} />
            <Route path="/Order" element={<Order />} />
            <Route path="/Blog" element={<Blog />} />
            <Route path="/ContactUs" element={<ContactUsPg />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/SignUp" element={<SignUp />} />
          </Routes>
        </Box>
      )}
    </ThemeProvider>
  );
}

export default App;
