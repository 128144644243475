// import { LoginFailure } from "./action";
import {
  ADD_SINGLE_ITEM_QUANTITY,
  ADD_TO_CART,
  ADD_USER_DETAIL,
  CLOSE_FORM_LIST,
  CREATE_ORDER,
  GET_ALL_ORDERS,
  LOGIN_FAILURE,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGOUT,
  MINUS_SINGLE_ITEM_QUANTITY,
  OPEN_FORM_LIST,
  REMOVE_ALL_CART,
  REMOVE_FORM_CART,
} from "./constant";

const Reducer = (state, action) => {
  // console.log(action);
  switch (action.type) {
    // AUTH CONFIG
    case LOGIN_START:
      return {
        ...state,
        user: null,
        isLoading: true,
        error: false,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isLoading: false,
        error: false,
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        isLoading: false,
        error: true,
      };

    case LOGOUT:
      return {
        ...state,
        user: null,
        cart: [],
        isLoading: false,
        error: true,
      };

    // CART CONFIG
    case ADD_TO_CART:
      const isItem = state.cart.find((item) => item.id === action.payload.id);
      if (!isItem) {
        state.cart.push({
          ...action.payload,
        });
      }

      return {
        ...state,
        cart: [...state.cart],
      };

    case REMOVE_ALL_CART:
      return {
        ...state,
        cart: [],
      };

    case REMOVE_FORM_CART:
      return {
        ...state,
        cart: state.cart.filter((item) => item.id !== action.payload),
      };

    case ADD_SINGLE_ITEM_QUANTITY:
      state.cart.map((item) => {
        if (item.id === action.payload) {
          return item.quantity++;
        }
        return item;
      });

      return {
        ...state,
        cart: [...state.cart],
      };

    case MINUS_SINGLE_ITEM_QUANTITY:
      state.cart.map((item) => {
        if (item.id === action.payload) {
          return item.quantity--;
        }
        return item;
      });

      return {
        ...state,
        cart: [...state.cart],
      };

    // ORDER CONFIG
    case GET_ALL_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };

    // ORDER CONFIG
    case CREATE_ORDER:
      return {
        ...state,
        orders: action.payload,
      };

    // form list
    case CLOSE_FORM_LIST:
      return {
        ...state,
        isOpenFormList: false,
      };

    case OPEN_FORM_LIST:
      return {
        ...state,
        isOpenFormList: true,
      };

    // user detail
    case ADD_USER_DETAIL:
      return {
        ...state,
        userDetail: action.payload,
      };

    default:
      return {
        state,
      };
  }
};

export default Reducer;
