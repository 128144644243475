import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  styled,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Stack } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import { Context } from "../contexAPI/stateProvider";
import { AddCart } from "../contexAPI/action";
import { message } from "antd";

function Product({ product }) {
  const MyCard = styled(Card)(({ theme }) => ({
    position: "relative",
    width: "300px",
    height: "250px",
    alignItems: "flex-end",
    overflow: "hidden",
    "&:hover": {
      boxShadow: " 0px 3px 13px 0px rgba(145,121,121,0.75)",
    },
  }));

  const MyContentBox = styled(Box)(({ theme }) => ({
    position: "absolute",
    bottom: 0,
    width: "100%",
    padding: "15px 25px",
    boxSizing: "border-box",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",

    background: "rgba(0.5, 0.5, 0.5, 0.582)",
    color: "white",
    opacity: "0.6",
    transform: "translateY(80%)",
    transition: "all 0.35s ease",
    height: "100%",
    "&:hover": {
      transform: "translateY(0)",
      background: "rgba(0, 0, 0, 0.582)",
      opacity: "1",
    },
  }));

  const MyCardMedia = styled(CardMedia)(({ theme }) => ({
    "&:hover": {
      transform: "scale(0.8)",
      transition: "0.5s",
    },
  }));

  const MyProStatus = styled(Typography)(({ theme }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2,
    background: "#BFE3E6",
    p: 2,
    width: "75px",
    height: "25px",
    color: "blue",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Georgia",
  }));

  const MyAddCart = styled(Button)(({ theme }) => ({
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 2,
    background: "#EFE1CE",
    p: 4,
    width: "95px",
    height: "25px",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Georgia",
    "&:hover": {
      backgroundColor: "#EFE1CE",
      fontWeight: "bold",
      boxShadow: "none",
    },
  }));

  const MyProTitle = styled(Typography)(({ theme }) => ({
    background: "black",
    opacity: ".4",
    color: "white",
    textAlign: "center",
    padding: "5px",
    borderRadius: "5px",
    fontFamily: "Georgia",
  }));

  // method and state

  const { dispatch, cart } = useContext(Context);
  const [quantity, setQuantity] = useState(1);

  //handle method
  const handleAddCart = () => {
    const newCart = {
      id: product.id,
      title: product.title,
      img: product.img,
      isNew: product.isNew,
      des: product.desc,
      status: product.status,
      quantity: quantity,
      price: product.price,
    };
    try {
      const isItem = cart.find((item) => item.id === product.id);
      // if (isItem) isItem.quantity += quantity;
      if (isItem) {
        isItem.quantity += quantity;
        message.info(
          "you are adding the quantity of already exited item,  go to the cart to control the quantity"
        );
      } else {
        dispatch(AddCart(newCart)); // distrust to protect the iteration problem
        message.success(`you added an item to cart successfully`);
      }
    } catch (error) {}
    // console.log(state);
  };

  const MyCardContent = styled(CardContent)(({ theme }) => ({}));
  return (
    <MyCard sx={{ borderRadius: "10px" }}>
      <MyProStatus>{product.status}</MyProStatus>
      {product.price !== null ? (
        <MyAddCart onClick={handleAddCart}>ADD TO CART</MyAddCart>
      ) : (
        <MyAddCart>Available</MyAddCart>
      )}
      <Link to={`/Product/${product?.id}`}>
        <MyCardMedia component="img" alt="product" image={product.img} />
      </Link>
      <MyContentBox>
        <Stack
          direction="row"
          gap={5}
          sx={{
            alignItems: "center",
            color: "white",
          }}
        >
          <ArrowUpwardIcon />
          {product.price !== null && (
            <Typography variant="h5">
              ETB: {new Intl.NumberFormat().format(product.price)}
            </Typography>
          )}
        </Stack>
        <MyCardContent>
          <MyProTitle gutterBottom variant="h5">
            {product.title}
          </MyProTitle>
        </MyCardContent>
        {/* <CardActions>
          <Link to={`/Product/${product?.id}`}>
            <Button
              size="large"
              sx={{
                color: "black",
                border: "1px solid white",
                marginLeft: "80px",
                background: "white",
                opacity: ".4",
                "&:hover": {
                  color: "white",
                },
              }}
            >
              <SearchIcon />
            </Button>
          </Link>
        </CardActions> */}
      </MyContentBox>
    </MyCard>
  );
}

export default Product;
