import { Box, IconButton, Stack, styled, Typography } from "@mui/material";

import React from "react";
import EdgesensorLowIcon from "@mui/icons-material/EdgesensorLow";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from "@mui/icons-material/Mail";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
// import img1 from "../images/smallBackImg4.jpg";

function Announcement() {
  const MyMainBox = styled(Box)(({ theme }) => ({
    width: "100%",

    p: 1,
    backgroundColor: "blue",
    // position: "sticky",
    // top: 0,
    // borderRadius: "10px",
    background: "#3b5998",
    // backgroundImage: `url(${img1})`,

    // backgroundSize: "cover",
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center",
    // border: "1px solid red",

    zIndex: 999,
    [theme.breakpoints.down("md")]: {
      height: "auto",

      // display: "none",
    },
  }));

  const MyMainStack = styled(Stack)(({ theme }) => ({
    height: "80px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px",

    // border: "1px solid red",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      flexDirection: "row",
      justifyContent: "space-around",

      // justifyContent: "spa" ,
      // gap: "6px",
    },
  }));

  const contactStyle = {
    color: "white",
    // backgroundColor: "rgba(2, 0, 0, 0.4)",
    p: 1,
    borderRadius: "5px",
    display: { xs: "none", sm: "block" },
  };

  const iconStyle = {
    color: "white",
    // width: { xs: "10px" },
  };
  return (
    <MyMainBox>
      <MyMainStack>
        <Typography
          sx={{ color: "white", display: { xs: "none", sm: "block" } }}
        >
          Get a super quality and optimum price products from our company
        </Typography>
        <Stack
          sx={{ flex: { xs: 1, sm: 4 } }}
          direction={{ xs: "row", sm: "row" }}
          spacing={1}
        >
          <IconButton
            sx={{ display: { xs: "none", sm: "block" }, color: "white" }}
          >
            <EdgesensorLowIcon />
            <a href="tel:+251 93 666 6875" style={{ textDecoration: "none" }}>
              <Typography sx={contactStyle} itemProp="telephone">
                : +251936666875
              </Typography>
            </a>
          </IconButton>
          <IconButton
            sx={{ display: { xs: "none", sm: "block" }, color: "white" }}
          >
            <LocalPhoneIcon />
            <a href="tel:+251 11 558 5446" style={{ textDecoration: "none" }}>
              <Typography sx={contactStyle} itemProp="telephone">
                : +251115585446
              </Typography>
            </a>
          </IconButton>
          <IconButton
            sx={{ display: { xs: "none", sm: "block" }, color: "white" }}
          >
            <MailIcon />
            <a
              href="mailto: info@starlightimports.com"
              style={{ textDecoration: "none" }}
            >
              <Typography sx={contactStyle} itemProp="telephone">
                : info@starlightimports.com
              </Typography>
            </a>
          </IconButton>
        </Stack>
        <Stack sx={{ flex: 1 }} direction="row" spacing={2}>
          <IconButton
            sx={iconStyle}
            href="https://www.facebook.com/profile.php?id=100088825805604"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon />
          </IconButton>

          <IconButton
            sx={iconStyle}
            href="https://twitter.com/StarlightimpoET"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon />
          </IconButton>

          <IconButton
            sx={iconStyle}
            href="https://www.instagram.com/starlight_et/"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon />
          </IconButton>

          <IconButton
            sx={iconStyle}
            href="https://t.me/starlightimport"
            target="_blank"
            rel="noreferrer"
          >
            <TelegramIcon />
          </IconButton>
        </Stack>
      </MyMainStack>
    </MyMainBox>
  );
}

export default Announcement;
