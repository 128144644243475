// CART CONSTANT
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FORM_CART = "REMOVE_FORM_CART";
export const REMOVE_ALL_CART = "REMOVE_ALL_CART";
export const ADD_SINGLE_ITEM_QUANTITY = "ADD_SINGLE_ITEM_QUANTITY";
export const MINUS_SINGLE_ITEM_QUANTITY = "MINUS_SINGLE_ITEM_QUANTITY";

// AUTH CONSTANT
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";

//ORDER CONSTANT
export const CREATE_ORDER = "CREATE_ORDER";
export const GET_ALL_ORDERS = "GET_ALL_ORDERS";

// OPEN FORM LIST
export const CLOSE_FORM_LIST = "CLOSE_FORM_LIST"
export const OPEN_FORM_LIST = "OPEN_FORM_LIST"

// USER DETAIL
export const ADD_USER_DETAIL = "ADD_USER_DETAIL"

