export const doFilter = (proTypes, setFilter, products) => {
  switch (proTypes) {
    case "IP":
      setFilter(products.filter((item) => item.proTypes === "IP"));
      break;
    case "Laptop":
      setFilter(products.filter((item) => item.proTypes === "Laptop"));
      break;
    case "HDCVI":
      setFilter(products.filter((item) => item.proTypes === "HDCVI"));
      break;
    case "Security":
      setFilter(products.filter((item) => item.proTypes === "Security"));
      break;
    case "Desktop":
      setFilter(products.filter((item) => item.proTypes === "Desktop"));
      break;

    case "OtherProduct":
      setFilter(products.filter((item) => item.proTypes === "OtherProduct"));
      break;

    default:
      setFilter(products);
      break;
  }
};
