import img1 from "../images/backgroundAbout.jpeg";
import img2 from "../images/visionAbout.jpeg";
import img3 from "../images/core-value.png";
// import img4 from "../images/core-value.png";

export const aboutData = [
  {
    id: 1,
    title: "Background",
    image: img1,
    desc: `Starlight is  a privately owned importing company in Addis Ababa, Ethiopia. It is established in 2021 with an initial capital of 10,000,000 birr. The company strives to advance networking and electronic import in Ethiopia.
    Starlight imports Cisco and Huawei brand routers and switches, laptops, computers , ethernet switches, and other electronic device from China, Dubai, and France. Through time we aim to corporate in partnership with China. We named it starlight to reflect upon the stars which gives lights when it’s too dark. Starlight intend to build a sustainable supply to create lightful cities..`,
  },

  {
    id: 2,
    title: "Company Vision and Mission",
    image: img2,
    desc: `Our vision is to change the impossible to possible by leading the market for efficient, convenient, and hassle- free supply in Ethiopia. Our mission is to inspire and enrich the world of science by efficiently providing professionals with high-quality tools`,
  },

  {
    id: 3,
    title: "Core Values",
    image: img3,
    desc: `Our core values are Quality, Integrity, Transparency, Efficiency, Commitment, Trust, Responsibility and Team Work.`,
  },
];
