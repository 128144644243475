import {
  ADD_SINGLE_ITEM_QUANTITY,
  ADD_TO_CART,
  ADD_USER_DETAIL,
  CLOSE_FORM_LIST,
  CREATE_ORDER,
  GET_ALL_ORDERS,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGOUT,
  MINUS_SINGLE_ITEM_QUANTITY,
  OPEN_FORM_LIST,
  REMOVE_ALL_CART,
  REMOVE_FORM_CART,
} from "./constant";

// auth action

export const LoginStart = () => ({
  type: LOGIN_START,
});

export const LoginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
});

export const LoginFailure = (error) => ({
  type: "LOGIN_FAILURE",
  payload: error,
});

export const LogOut = () => ({
  type: LOGOUT,
});

// cart action
export const AddCart = (data) => ({
  type: ADD_TO_CART,
  payload: data,
});

export const RemoveCart = (id) => ({
  type: REMOVE_FORM_CART,
  payload: id,
});

export const RemoveAllCarts = () => ({
  type: REMOVE_ALL_CART,
});

//order action
export const getAllOrder = (data) => ({
  type: GET_ALL_ORDERS,
  payload: data,
});

export const createOrder = (data) => ({
  type: CREATE_ORDER,
  payload: data,
});

// form list action
export const doCloseFormList = () => ({
  type: CLOSE_FORM_LIST,
});

export const doOpenFormList = () => ({
  type: OPEN_FORM_LIST,
});

// user detail
export const addUserDetail = (data) => ({
  type: ADD_USER_DETAIL,
  payload: data,
});

// item quantity
export const addQuantity = (id) => ({
  type: ADD_SINGLE_ITEM_QUANTITY,
  payload: id,
});

export const minusQuantity = (id) => ({
  type: MINUS_SINGLE_ITEM_QUANTITY,
  payload: id,
});

