import img1 from "../images/printerCat.webp";
import img2 from "../images/computerCat.jpeg";
import img3 from "../images/cameraCat.jpg";

export const catList = [
  {
    id: 1,
    title: "Computer accessories and other related product",
    img: img1,
    desc: "You are now in computer accessories and other related products of category lists",
    categoryType: "computer_accessories_others",
  },
  {
    id: 2,
    title: "Computers and Servers",
    img: img2,
    desc: "You are now in computers and servers of category lists",
    categoryType: "computers_servers",
  },
  {
    id: 3,
    title: "Security, Surveillance camera and its accessories",
    img: img3,
    desc: "You are now in security, surveillance camera and its accessories of category lists",
    categoryType: "security_camera_accessories",
  },
];
