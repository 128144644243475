// access control
import imgScreenMonitor from "../images/screenMonitor_DS-D5043QR.jpg";
import imgScreenMonitor1 from "../images/screenMonitor_DS-D5043QR1.jpg";
import imgScreenMonitor2 from "../images/screenMonitor_DS-D5043QR2.jpg";

// shelf

import imgShelf from "../images/Shelf_DS-D5AWQ.png";
import imgShelf1 from "../images/Shelf_DS-D5AWQ1.jpg";
import imgShelf2 from "../images/Shelf_DS-D5AWQ2.jpg";

//  laptop
import imgAsusLap from "../images/asusLaptop.jpg";
import imgAsusLap1 from "../images/asusLaptop1.jpg";
import imgAsusLap2 from "../images/asusLaptop2.webp";

//  Face Recognition Terminal
import imgFaceRecognition from "../images/Face Recognition Terminal_DS-KIT671MF.jpg";
import imgFaceRecognition1 from "../images/Face Recognition Terminal_DS-KIT671MF1.webp";
import imgFaceRecognition2 from "../images/Face Recognition Terminal_DS-KIT671MF2.jpg";

// Screen Monitors Bracket
import imgScreenMonitorBracket from "../images/Screen Monitors Bracket_DS-DM4255W.jpg";
import imgScreenMonitorBracket1 from "../images/Screen Monitors Bracket_DS-DM4255W1.png";
import imgScreenMonitorBracket2 from "../images/Screen Monitors Bracket_DS-DM4255W2.jpg";

//  lenovo Desktop
import imgLenovoDesk from "../images/lenovoDesktop.png";
import imgLenovoDesk1 from "../images/lenovoDesktop1.png";
import imgLenovoDesk2 from "../images/lenovoDesktop2.webp";

//  hp Desktop
import imgHpDesktop from "../images/hpDesktop.jpg";
import imgHpDesktop1 from "../images/hpDesktop1.webp";
import imgHpDesktop2 from "../images/hpDesktop2.jpeg";

//  finger print Access Control Teminal
import imgFingerPrintAccess from "../images/finger print Access Control Teminal_DS-KIT804BMF.webp";
import imgFingerPrintAccess1 from "../images/finger print Access Control Teminal_DS-KIT804BMF1.jpg";
import imgFingerPrintAccess2 from "../images/finger print Access Control Teminal_DS-KIT804BMF.webp";

//  Magnetic Lock Bracket
import imgMagneticLockBracket from "../images/Magnetic Lock Bracket DS-K4H258-L2.png";
import imgMagneticLockBracket1 from "../images/Magnetic Lock Bracket DS-K4H258-L21.webp";
import imgMagneticLockBracket2 from "../images/Magnetic Lock Bracket DS-K4H258-L22.jpg";

//  Door Exit Button
import imgDoorExitBtn from "../images/Door Exit Button _DS-K7P01.png";
import imgDoorExitBtn1 from "../images/Door Exit Button _DS-K7P011.jpg";
import imgDoorExitBtn2 from "../images/Door Exit Button_DS-K7P012.webp";

//  Card issuer
import imgCardIssuer from "../images/Card issuer_DS-KIF100-D8E.png";
import imgCardIssuer1 from "../images/Card issuer_DS-KIF100-D8E1.jpg";
import imgCardIssuer2 from "../images/Card issuer_DS-KIF100-D8E2.webp";

//  RFID Card
import imgRfidCard from "../images/RFID Card_IC S50.jpg";
import imgRfidCard1 from "../images/RFID Card_IC S501.jpg";
import imgRfidCard2 from "../images//RFID Card_IC S502.jpeg";

//  Video Intercom
import imgVideoIntercomKd from "../images/Video Intercom_DS-KD8103-E6.png";
import imgVideoIntercomKd1 from "../images/Video Intercom_DS-KD8103-E61.jpg";
import imgVideoIntercomKd2 from "../images/Video Intercom_DS-KD8103-E62.webp";

//  Video Intercom
import imgVideoIntercomKv from "../images/Video Intercom DS-KV6113-WPEI.jpeg";
import imgVideoIntercomKv1 from "../images/Video Intercom DS-KV6113-WPEI1.jpeg";
import imgVideoIntercomKv2 from "../images/Video Intercom DS-KV6113-WPEI2.jpg";

//  Magnetic Lock
import imgMagneticLock from "../images/Magnetic Lock_DS-K4H258S.jpeg";
import imgMagneticLock1 from "../images/Magnetic Lock_DS-K4H258S1.jpeg";
import imgMagneticLock2 from "../images/Magnetic Lock_DS-K4H258S2.jpg";

//  Network Camera
import imgNetworkCamera from "../images/Network Camera DS-2CD2623G2-I2S1.jpeg";
import imgNetworkCamera1 from "../images/Network Camera DS-2CD2623G2-I2S2.png";
import imgNetworkCamera2 from "../images/Network Camera_DS-2CD2623G2-I2S.jpg";

// Exir Fixed Bullent Network Camera
import imgExirFixedBullentCamera from "../images/Exir Fixed Bullent Network Camera_DS-2CD2T23G2-2I.jpg";
import imgExirFixedBullentCamera1 from "../images/Exir Fixed Bullent Network Camera_DS-2CD2T23G2-2I1.png";
import imgExirFixedBullentCamera2 from "../images/Exir Fixed Bullent Network Camera_DS-2CD2T23G2-2I2.jpg.jpg";

//  Exir Varifocal Dome Network Camera
import imgExirVarifocal from "../images/Exir Varifocal Dome Network Camera_DS-2CD2723G2-I2S.jpg";
import imgExirVarifocal1 from "../images/Exir Varifocal Dome Network Camera_DS-2CD2723G2-I2S1.jpg";
import imgExirVarifocal2 from "../images/Exir Varifocal Dome Network Camera_DS-2CD2723G2-I2S2.jpg";

//  IR Panoramic Fisheye Camera
import imgPanoramicFisheyeCamera from "../images/IR Panoramic Fisheye Camera_DS-2CD6365GOE-IS.jpg";
import imgPanoramicFisheyeCamera1 from "../images/IR Panoramic Fisheye Camera_DS-2CD6365GOE-IS1.jpg";
import imgPanoramicFisheyeCamera2 from "../images/IR Panoramic Fisheye Camera_DS-2CD6365GOE-IS2.jpeg";

// Indoor/Outdoor IR Speed Dome Camera
import imgIndoorOutDoorSpeedDomeCamera from "../images/IndoorOutdoor IR Speed Dome Camera_DS-2DE5425IW-AE S6.jpg";
import imgIndoorOutDoorSpeedDomeCamera1 from "../images/IndoorOutdoor IR Speed Dome Camera_DS-2DE5425IW-AE S61.jpg";
import imgIndoorOutDoorSpeedDomeCamera2 from "../images/IndoorOutdoor IR Speed Dome Camera_DS-2DE5425IW-AE S62.jpg";

// Network Video Recorder
import imgNetworkVideoRecorder from "../images/Network Video Recorder_DS-7608NI-K28P.jpg";
import imgNetworkVideoRecorder1 from "../images/Network Video Recorder_DS-7608NI-K28P1.jpg";
import imgNetworkVideoRecorder2 from "../images/Network Video Recorder_DS-7608NI-K28P2.png";

// Metal Detector Door
import imgMetalDetectorDoor from "../images/Metal Detector Door_NP-SG112L.png";
import imgMetalDetectorDoor1 from "../images/Metal Detector Door_NP-SG112L1.webp";
import imgMetalDetectorDoor2 from "../images/Metal Detector Door_NP-SG112L2.webp";

// Wall Mount Bracket Indoor/Outdoor
import imgMountBracketIndoor from "../images/Bracket_DS-16022S.jpg";
import imgMountBracketIndoor1 from "../images/Bracket_DS-16022S1.jpg";
import imgMountBracketIndoor2 from "../images/Bracket_DS-16022S2.jpg";

// Conference Flat Panel
import imgConferenceFlatPanel from "../images/Conference Drive_DS-D5B86RBB.jpg";
import imgConferenceFlatPanel1 from "../images/Conference Drive_DS-D5B86RBB1.jpeg";
import imgConferenceFlatPanel2 from "../images/Conference Drive_DS-D5B86RBB2.jpeg";

// Handheld Metal Detector
import imgHandheldMetalDetector from "../images/Hand-HELd Metal Detector NP-SH100.jpg";
import imgHandheldMetalDetector1 from "../images/Hand-HELd Metal Detector NP-SH1001.jpeg";
import imgHandheldMetalDetector2 from "../images/Hand-HELd Metal Detector NP-SH100.jpg";

// // Handheld Metal Detector
// import imgHandheldMetalDetector from "../images/Hand-HELd Metal Detector NP-SH100.jpg";
// import imgHandheldMetalDetector1 from "../images/Hand-HELd Metal Detector NP-SH1001.jpeg";
// import imgHandheldMetalDetector2 from "../images/Hand-HELd Metal Detector NP-SH100.jpg";

export const proList = [
  {
    id: 1,
    title: "Screen Monitor",
    img: imgScreenMonitor,
    imgOne: imgScreenMonitor1,
    imgTwo: imgScreenMonitor2,
    modelName: "DS-D5043QR",
    brandName: "Hikvision",
    desc: `LED backlit technology with full HD 1920×1080, 16.7 million color, display picture perfectly, 
    HDMI support up to 1080P, Multiple inputs: HDMI, VGA, Build-in speaker，Audio 5W*2, 
    Auto signal input Detection, Smart engine for Phase/Image position/Color calibration, 
    Plastic casing.`,
    price: 71712,
    isNew: true,
    proTypes: "security", // ?
    status: "inStock",
    ProCategory: "computer_accessories_others",
    pdfUrl: "https://files.fm/f/ynqxytthy",
  },
  {
    id: 2,
    title: "Interactive Display Wall-mounted Bracket",
    img: imgShelf,
    imgOne: imgShelf1,
    imgTwo: imgShelf2,
    modelName: "DS-D5AW/Q(0-STD)",
    brandName: "Hikvision",
    desc: `Wall-mounted Bracket for 65”-86’’ Interactive display(Suitable model shown in the end), 
    Solid Steel structure, Prevent screen bend or twist, Cold-rolled Steel Plate(SPCC).`,
    price: 4932,
    isNew: true,
    proTypes: "security",
    status: "inStock",
    ProCategory: "security_camera_accessories",
    pdfUrl: "https://files.fm/f/km8gawfw2",
  },
  {
    id: 3,
    title: "Asus laptop",
    img: imgAsusLap,
    imgOne: imgAsusLap1,
    imgTwo: imgAsusLap2,
    modelName: "xxxxxxxxxx",
    brandName: "Asus",
    desc: "xxxxxxxxxxxxxxxxxxxxxxxxx",
    price: null,
    isNew: false,
    proTypes: "Laptop",
    status: "inStock",
    ProCategory: "computers_servers",
    pdfUrl: null,
  },
  {
    id: 4,
    title: "Face Recognition",
    img: imgFaceRecognition,
    imgOne: imgFaceRecognition1,
    imgTwo: imgFaceRecognition2,
    modelName: "DS-KIT671MF",
    brandName: "Hikvision",
    desc: `The device shall support face recognition in dark environment, 
    he device face recognition distance shall be from 0.3 m to 3 m; The suggested person
height for recognition shall be from 1.4 m to 1.9 m, The device shall support a face capacity of 5,000, 
The camera of the device shall contain 2 MP wide-angle dual-lens for face capturing.`,
    price: 40959,
    isNew: true,
    proTypes: "IP",
    status: "inStock",
    ProCategory: "security_camera_accessories",
    pdfUrl: "https://files.fm/f/8cpbme2ag",
  },
  {
    id: 5,
    title: "Screen Monitor Bracket",
    img: imgScreenMonitorBracket,
    imgOne: imgScreenMonitorBracket1,
    imgTwo: imgScreenMonitorBracket2,
    modelName: "DS-DM4255W",
    brandName: "Hikvision",
    desc: `Wall-mounted Bracket for 42’’/43’’/49’/55’’’ Monitor(Suitable model shown in the end), 
    Solid Steel structure, Prevent screen bend or twist, Cold-rolled Steel Plate(SPCC).`,
    price: 1942,
    isNew: true,
    proTypes: "security",
    status: "inStock",
    ProCategory: "security_camera_accessories",
    pdfUrl: "https://files.fm/f/r24mexvxk",
  },
  {
    id: 6,
    title: "Lenovo Desktop",
    img: imgLenovoDesk,
    imgOne: imgLenovoDesk1,
    imgTwo: imgLenovoDesk2,
    modelName: "xxxxxxxxx",
    brandName: "Lenovo",
    desc: "xxxxxxxxxxxxxxxxxxxxxxxxxxx",
    price: null,
    isNew: false,
    proTypes: "Desktop",
    status: "inStock",
    ProCategory: "computers_servers",
    pdfUrl: null,
  },
  {
    id: 7,
    title: "hp Desktop",
    img: imgHpDesktop,
    imgOne: imgHpDesktop1,
    imgTwo: imgHpDesktop2,
    modelName: "xxxxxxxxx",
    brandName: "Hp",
    desc: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
    price: null,
    isNew: false,
    proTypes: "Desktop",
    status: "inStock",
    ProCategory: "computers_servers",
    pdfUrl: null,
  },
  {
    id: 8,
    title: "Finger Print Access Control Terminal",
    img: imgFingerPrintAccess,
    imgOne: imgFingerPrintAccess1,
    imgTwo: imgFingerPrintAccess2,
    modelName: "DS-KIT804BMF",
    brandName: "Hikvision",
    desc: `3,000 fingerprints, 3,000 cards and 100,000 events storage, 3,000 fingerprints, 3,000 cards and 100,000 events storage
    Built-in card reader for Mifare1 card, Communication via TCP/TP and Wi-Fi, 
    Supports Wiegand interface, Alarm input and output interface, ISUP5.0，ISAPI.`,
    price: 14492,
    isNew: true,
    proTypes: "security",
    status: "inStock",
    ProCategory: "computer_accessories_others",
    pdfUrl: "https://files.fm/f/bv3z4hazp",
  },
  {
    id: 9,
    title: "Magnetic Lock Bracket",
    img: imgMagneticLockBracket,
    imgOne: imgMagneticLockBracket1,
    imgTwo: imgMagneticLockBracket2,
    modelName: "DS-K4H258-L2",
    brandName: "Hikvision",
    desc: `Adopts aldural material, Hard anodizing electroplating operated, 
    L-bracket is used for fixing the lock body; Z-bracket is used for fixing the armature plate, 
    Suitable for wooden door, and metal door.`,
    price: 2195,
    isNew: false,
    proTypes: "security",
    status: "inStock",
    ProCategory: "security_camera_accessories",
    pdfUrl: "https://files.fm/f/d7r36ap8n",
  },
  {
    id: 10,
    title: "Door Exit Button",
    img: imgDoorExitBtn,
    imgOne: imgDoorExitBtn1,
    imgTwo: imgDoorExitBtn2,
    modelName: "DS-K7P01",
    brandName: "Hikvision",
    desc: `Dimension (L×W×H): 86mm × 86mm × 28.9 mm (3.39" × 3.39" × 1.14"), 
    Structure: Aluminum alloy panel, metal button, Current Rating: Max. 3 A@36 VDC, 
    Output Contact: NO/NC/COM contact, Aging Test: Already passed 500 thousand times aging test, 
    Suitable Doors: Hollow doorframe and embedded-electric box.`,
    price: 1312,
    isNew: true,
    proTypes: "security",
    status: "inStock",
    ProCategory: "security_camera_accessories",
    pdfUrl: "https://files.fm/f/zqn4tprja",
  },

  {
    id: 11,
    title: "Card Enrollment Station",
    img: imgCardIssuer,
    imgOne: imgCardIssuer1,
    imgTwo: imgCardIssuer2,
    modelName: "DS-KIF100-D8E",
    brandName: "Hikvision",
    desc: `Plug-and-play USB with no-driver technology, 2 PSAM cassettes (standard configuration), same size with 2 SIM cards in total, 
    Supports on-line upgrade, Supports various card types (Mifare card, CPU, PSAM, ID card, and EM card) with the card reading frequency
    13.56 MHz and 125 KHz, Well-defined API, Adopt high-level encryption algorithm to ensure the security for data communication.`,
    price: 5394,
    isNew: false,
    proTypes: "security",
    status: "inStock",
    ProCategory: "computer_accessories_others",
    pdfUrl: "https://files.fm/f/ef48sc6gt",
  },

  {
    id: 12,
    title: "RFID Card",
    img: imgRfidCard,
    imgOne: imgRfidCard1,
    imgTwo: imgRfidCard2,
    modelName: "IC S50",
    brandName: "Hikvision",
    desc: `Non-Contacting data transmission and supplied power(no battery needed), Working distance: no more than 100mm (depends on the antenna dimension), 
    Working frequency: 13.56 MHz, High data transmission speed: 106 k bit/s, 
    Data integrity protection: 16 bit CRC, odd-even check, encoding and counting
by bit, Data transmission and processing time: < 100 ms (including backup and
  management time).`,
    price: 1419,
    isNew: false,
    proTypes: "security",
    status: "inStock",
    ProCategory: "security_camera_accessories",
    pdfUrl: "https://files.fm/f/x2456umap",
  },

  {
    id: 13,
    title: "Video Intercom(DS-KD8103-E6)",
    img: imgVideoIntercomKd,
    imgOne: imgVideoIntercomKd1,
    imgTwo: imgVideoIntercomKd2,
    modelName: "DS-KD8103-E6",
    brandName: "Hikvision",
    desc: `2 MP HD camera with high quality image, 120 ° wide angle of view, 
    3.5-inch colorful LCD screen with 480 × 320 resolution, Unlocks by cards and pin-codes,
    Supports configuration via Web remotely.`,
    price: 32267,
    isNew: false,
    proTypes: "security",
    status: "inStock",
    ProCategory: "security_camera_accessories",
    pdfUrl: "https://files.fm/f/4q3cy5bmf",
  },
  {
    id: 14,
    title: "Video Intercom(DS-KV6113-WPEI)",
    img: imgVideoIntercomKv,
    imgOne: imgVideoIntercomKv1,
    imgTwo: imgVideoIntercomKv2,
    modelName: "DS-KV6113-WPEI(B)",
    brandName: "Hikvision",
    desc: `Standard PoE, 2 MP HD camera, Noise suppression and echo cancellation, 
    Low illumination, Access control functions, Tamper-proof, Configuration remotely via Web, 2.4 GHz Wi-Fi`,
    price: 14940,
    isNew: true,
    proTypes: "security",
    status: "inStock",
    ProCategory: "security_camera_accessories",
    pdfUrl: "https://files.fm/f/86qn64rjt",
  },

  {
    id: 15,
    title: "One-Door Magnetic Lock",
    img: imgMagneticLock,
    imgOne: imgMagneticLock1,
    imgTwo: imgMagneticLock2,
    modelName: "DS-K4H258S",
    brandName: "Hikvision",
    desc: `The magnetic lock supports static linear thrust of 280kg(550Lbs), Default voltage is 12VDC, 
    It is equipped with internal voltage dependent resistor (MOV), It is applied to wooden door, glass door, metal door and fireproof door, 
    LED indicator displays the status of door lock, Anti-residual magnetism design, 
    Abrasion-proof materials, The shell is made up of aldural and is hard anodizing electroplated.`,
    price: 3959,
    isNew: false,
    proTypes: "security",
    status: "inStock",
    ProCategory: "security_camera_accessories",
    pdfUrl: "https://files.fm/f/tugpgpv82",
  },

  {
    id: 16,
    title: "EXIR Motorized Varifocal Bullet Network Camera",
    img: imgNetworkCamera,
    imgOne: imgNetworkCamera1,
    imgTwo: imgNetworkCamera2,
    modelName: "DS-2CD2623G2-I2S",
    brandName: "Hikvision",
    desc: `High quality imaging with 2 MP resolution, Motorized varifocal lens for easy installation and, 
    monitoring, Clear imaging against strong backlight due to 120 dB WDR technology, Efficient H.265+ compression technology, 
    Water and dust resistant (IP67) and vandal resistant (IK10).`,
    price: 27566,
    isNew: true,
    proTypes: "security",
    status: "inStock",
    ProCategory: "security_camera_accessories",
    pdfUrl: "https://docdro.id/gcjU2ur",
  },

  {
    id: 17,
    title: "Exir Fixed Bullent Network Camera",
    img: imgExirFixedBullentCamera,
    imgOne: imgExirFixedBullentCamera1,
    imgTwo: imgExirFixedBullentCamera2,
    modelName: "DS-2CD2T23G2-2I",
    brandName: "Hikvision",
    desc: `High quality imaging with 2 MP resolution, Clear imaging against strong backlight due to 120 dB WDR, 
    technology, Efficient H.265+ compression technology, Water and dust resistant (IP67).`,
    price: 17351,
    isNew: true,
    proTypes: "security",
    status: "inStock",
    ProCategory: "security_camera_accessories",
    pdfUrl: "https://files.fm/f/n5mgxej9q",
  },

  {
    id: 18,
    title: "Exir Varifocal Dome Network Camera ",
    img: imgExirVarifocal,
    imgOne: imgExirVarifocal1,
    imgTwo: imgExirVarifocal2,
    modelName: "DS-2CD2723G2-I2S",
    brandName: "Hikvision",
    desc: `High quality imaging with 2 MP resolution, Motorized vari-focal lens for easy installation and, 
    monitoring, Clear imaging against strong back light due to 120 dB WDR technology, 
    Efficient H.265+ compression technology, Water and dust resistant (IP67) and vandal proof (IK10).`,
    price: 25566,
    isNew: true,
    proTypes: "security",
    status: "inStock",
    ProCategory: "security_camera_accessories",
    pdfUrl: "https://files.fm/f/bqy8dkh5e",
  },
  {
    id: 19,
    title: "IR Panoramic Fisheye Camera",
    img: imgPanoramicFisheyeCamera,
    imgOne: imgPanoramicFisheyeCamera1,
    imgTwo: imgPanoramicFisheyeCamera2,
    modelName: "DS-2CD6365GOE-IS",
    brandName: "Hikvision",
    desc: `1/1.8” Progressive Scan CMOS, Max. 3072 × 2048 @30fps,
    Up to 18 live view display modes, 120 dB WDR, Up to 15m IR range, 
    Built-in microphone and speaker, Smart features, 6 behavior analyses, and 3 exception detections.`,
    price: 40932,
    isNew: true,
    proTypes: "security",
    status: "inStock",
    ProCategory: "security_camera_accessories",
    pdfUrl: "https://files.fm/f/gbaepq5rn",
  },

  {
    id: 20,
    title: "Indoor/Outdoor IR Speed Dome Camera",
    img: imgIndoorOutDoorSpeedDomeCamera,
    imgOne: imgIndoorOutDoorSpeedDomeCamera1,
    imgTwo: imgIndoorOutDoorSpeedDomeCamera2,
    modelName: "DS-2DE5425IW-AE S6",
    brandName: "Hikvision",
    desc: `Accurate human and vehicle target classification, alarm, and
    linked tracking based on deep learning algorithm /ln, Support face capture. 
    Up to 5 faces captured at the same time, 1/2.8" Progressive Scan CMOS, 
    Up to 2560 × 1440 @30fps resolution,  25 × optical zoom, 16 × digital zoom.`,
    price: 69500,
    isNew: true,
    proTypes: "security",
    status: "inStock",
    ProCategory: "security_camera_accessories",
    pdfUrl: "https://files.fm/f/v7m865xag",
  },
  {
    id: 21,
    title: "Digital Metal Detector Door",
    img: imgMetalDetectorDoor,
    imgOne: imgMetalDetectorDoor1,
    imgTwo: imgMetalDetectorDoor2,
    modelName: "NP-SG112L",
    brandName: "Hikvision",
    desc: `Detects micro metals like half paper clip, Detects all metal objects more than 2cm above the ground, 
    Detects metal objects thrown through the door, Detects multiple metals in different positions of the human body simultaneously, 
    12 independent detection zones with each zone of the sensitivity level of 1000, 
    11 kinds of working frequency available or manual setting from 7000 to 8999Hz, 
    Nixie tube display with high light, Anti-interference design, multiple inspection door working at the same time without
    mutual interference.`,
    price: 118259,
    isNew: true,
    proTypes: "security",
    status: "inStock",
    ProCategory: "security_camera_accessories",
    pdfUrl: "https://files.fm/f/ef48sc6gt",
  },

  {
    id: 22,
    title: "Network Video Recorder",
    img: imgNetworkVideoRecorder,
    imgOne: imgNetworkVideoRecorder1,
    imgTwo: imgNetworkVideoRecorder2,
    desc: "DS-7608NI-K2/8P",
    price: 36635,
    isNew: true,
    proTypes: "security",
    status: "inStock",
    ProCategory: "security_camera_accessories",
    pdfUrl: "https://files.fm/f/ef48sc6gt",
  },

  {
    id: 23,
    title: "Wall Mount Bracket Indoor/Outdoor",
    img: imgMountBracketIndoor,
    imgOne: imgMountBracketIndoor1,
    imgTwo: imgMountBracketIndoor2,
    modelName: "DS-1602ZJ",
    brandName: "Hikvision",
    desc: `Aluminum alloy material with surface spray treatment, 
    , General design for indoor/outdoor, Better water proof design, With safety rope, 
    the assembling is safer and more convenient.`,
    price: 2339,
    isNew: true,
    proTypes: "security",
    status: "inStock",
    ProCategory: "security_camera_accessories",
    pdfUrl: "https://files.fm/f/brv236wkh",
  },

  {
    id: 24,
    title: "Conference Flat Panel",
    img: imgConferenceFlatPanel,
    imgOne: imgConferenceFlatPanel1,
    imgTwo: imgConferenceFlatPanel2,
    modelName: "DS-D5B86RB/B",
    brandName: "Hikvision",
    desc: `4K Display Presenting More Details: 4K resolution improves the image details with the features of being anti-glared, 
    fingerprint-resistant, and strong light-blocked, Instant and Ultra-Fine Writing: Provides smooth writing, 
    with the touch precision of 1 mm, and smart stroke optimization makes every stroke more real.`,
    price: 731513,
    isNew: true,
    proTypes: "security",
    status: "inStock",
    ProCategory: "security_camera_accessories",
    pdfUrl: "https://files.fm/f/xghry2yzh",
  },

  {
    id: 25,
    title: "Handheld Metal Detector",
    img: imgHandheldMetalDetector,
    imgOne: imgHandheldMetalDetector1,
    imgTwo: imgHandheldMetalDetector2,
    modelName: "NP-SH100",
    brandName: "Hikvision",
    desc: `Adjustable detection sensitivity for different requirements, Low battery alarm, Two alarm modes: vibrative alarm, and audible & visual alarm, 
    LED for battery/charging indication, Cooperates with battery for charging, High detection speed with big probe area, 
    Drop-proof (1 m drop protection), Standard 9 V (6F22) battery or storage battery (optional).`,
    price: 6468,
    isNew: true,
    proTypes: "security",
    status: "inStock",
    ProCategory: "security_camera_accessories",
    pdfUrl: "https://files.fm/f/hsmbubrj8",
  },
];
