import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
// import { Typography } from "antd";
import React, { useContext } from "react";
import { Context } from "../contexAPI/stateProvider";
// import { CSVLink } from "react-csv";
import startLogo from "../images/newStart1.png";
import PrintIcon from "@mui/icons-material/Print";
import { Tooltip } from "antd";

function OrderDetail() {
  const { orders, userDetail } = useContext(Context);

  const textStyle = {
    fontFamily: "Georgia",
    color: "blue",
    fontSize: { xs: "9px", sm: "16px" },
  };

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <Tooltip
          placement="right"
          title={"click here to print"}
          color="#108ee9"
          style={{ color: "black" }}
        >
          <Button variant="outlined" onClick={() => window.print()}>
            <PrintIcon />
          </Button>
        </Tooltip>

        <img src={startLogo} alt="" width="120px" />
      </Box>
      {/* <CSVLink data={orders}> DownLoad </CSVLink>; */}
      <Box sx={{ p: 2 }}>
        <Typography
          variant="h5"
          sx={{
            textAlign: "center",
            fontFamily: "Georgia",
            color: "blue",
          }}
        >
          User Detail
        </Typography>
        <Box
          sx={{
            p: { xs: 1, sm: 2 },
            display: "flex",
            flexWrap: "wrap",
            // flexDirection: "column",
            gap: { xs: "5px", sm: "10px" },
            background: "#f2e6e6",
            justifyContent: "space-around",
            // width: "50%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              background: "#f2e6e6",
              justifyContent: "space-around",
              width: { xs: "100%", sm: "auto" },
              // border: "1px solid gray",
            }}
          >
            <Typography sx={textStyle}>
              User ID:
              <strong>{orders.userId}</strong>
            </Typography>
            <Typography sx={textStyle}>
              User Name:
              <strong>{orders.userName}</strong>
            </Typography>
            <Typography sx={textStyle}>
              User Address:
              <strong>{orders.userAddress}</strong>
            </Typography>
            <Typography sx={textStyle}>
              User Phone Number:
              <strong>{orders.userPhoneNumber}</strong>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              background: "#f2e6e6",
              justifyContent: "space-around",
              width: { xs: "100%", sm: "auto" },
            }}
          >
            <Typography sx={textStyle}>
              User Email: <strong>{orders.email}</strong>
            </Typography>

            <Typography sx={textStyle}>
              Order Date: <strong>{orders.orderDate}</strong>
            </Typography>
            <Typography sx={textStyle}>
              Visa Account:
              <strong>{orders.userVisaAccount}</strong>
            </Typography>
            <Typography sx={textStyle}>
              Payment Method:
              <strong>{orders.userPaymentMethod}</strong>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ p: 2 }}>
        <Typography
          variant="h5"
          sx={{
            textAlign: "center",
            color: "blue",
            fontFamily: "Georgia",
          }}
        >
          Products Detail
        </Typography>
        <TableContainer component={Paper} sx={{ marginTop: "10px" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                    background: "#f2e6e6",
                    color: "blue",
                    fontFamily: "Georgia",
                    fontSize: { xs: "13px", sm: "16px" },
                  },
                }}
              >
                <TableCell>Product ID</TableCell>
                <TableCell align="right">Product Title</TableCell>
                <TableCell align="right">Product Image</TableCell>
                <TableCell align="right">Product Status</TableCell>
                <TableCell align="right">Product Quantity</TableCell>
                <TableCell align="right">Product Price(ETB)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.products.map((product) => (
                <TableRow
                  key={product.productId}

                  // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell sx={textStyle}>{product.productId}</TableCell>
                  <TableCell align="right" sx={textStyle}>
                    {product.productTitle}
                  </TableCell>
                  <TableCell align="right">
                    <img
                      src={product.productImg}
                      alt="product img"
                      width="45px"
                      style={{
                        cursor: "pointer",
                        "&:hover": {
                          cur: "70px",
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell align="right" sx={textStyle}>
                    {product.productStatus}
                  </TableCell>
                  <TableCell align="right" sx={textStyle}>
                    {product.productQuantity}
                  </TableCell>
                  <TableCell align="right" sx={textStyle}>
                    {new Intl.NumberFormat().format(product.productPrice)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            marginTop: "30px",
            display: "flex",
            justifyContent: "space-around",
            color: "blue",
            fontFamily: "Georgia",
          }}
        >
          <Typography sx={textStyle}>Total Amount</Typography>
          <Typography sx={{ background: "#f2e6e6", p: 2 }}>
            ETB:
            {new Intl.NumberFormat().format(orders.totalAmount)}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ p: 2 }}>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            fontFamily: "Georgia",
            color: "blue",
          }}
        >
          Contact Information For Purchasing Our Products From Us
        </Typography>
        <Stack sx={{ background: "#f2e6e6" }} spacing={2}>
          <Typography sx={textStyle}>
            For Mobile Bank Users Pay At Bank Account NO:
            <strong>1000342905057</strong>
          </Typography>
          <Typography sx={textStyle}>
            Or Pay At Local Office:(Use This Address And Contact NO),
            <span>Mobile:</span>{" "}
            <strong>+251 93 666 6875/Phone:+251 11 558 5446, </strong>
            Office NO:
            <strong>
              1008,10th floor, Getu Commercial Building, Bole, Addis Ababa,{" "}
            </strong>
          </Typography>

          <Typography sx={textStyle}>
            <strong
              style={{
                background: "black",
                color: "white",
                padding: "5px",
                marginTop: "50px",
              }}
            >
              Please Print Your Purchase Detail Information From This Page
              Before Closing The Page
            </strong>
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
}

export default OrderDetail;
