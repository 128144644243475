import { Box, Stack } from "@mui/material";
import React from "react";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Announcement from "../components/Announcement";
import Map from "../components/Map";

function ContactUsPg() {
  return (
    <Box sx={{ width: "100%" }}>
      <Stack spacing={2}>
        <Announcement />
        <Navbar />
        <ContactUs />
        <Map />
        <Footer />
      </Stack>
    </Box>
  );
}

export default ContactUsPg;
