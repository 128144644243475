import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, getDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBoU5l3fZY32cF_Ryhkh0PiLJ8CXsnZbFc",
  authDomain: "starlight-952c9.firebaseapp.com",
  projectId: "starlight-952c9",
  storageBucket: "starlight-952c9.appspot.com",
  messagingSenderId: "982834432727",
  appId: "1:982834432727:web:89c2266ad1b626b1267e92",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth();
// export const get = getDoc();

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
