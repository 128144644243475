import { Box, Typography } from "@mui/material";
import React from "react";

function Map() {
  return (
    <Box
      sx={{
        // border: "1px solid black",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        p: 5,
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box>
        <Typography
          variant="h4"
          sx={{ textAlign: "center", fontFamily: "Georgia", color: "blue" }}
        >
          Get Us With Location
        </Typography>
      </Box>
      <div style={{ width: "800px", position: "relative" }}>
        <iframe
          title="map"
          style={{ width: "100%", height: "500px" }}
          src="https://maps.google.com/maps?&amp;height=500&amp;hl=en&amp;q=Addis%20Ababa%2C%20Bole%2C%20Getu%20Commercial+(Starlightimport%20Map)&amp;ie=UTF8&amp;t=&amp;z=10&amp;iwloc=B&amp;output=embed"
        ></iframe>
        <div
          style={{
            position: "absolute",
            // width: "100%",
            bottom: "10px",
            left: 0,
            right: 0,
            marginLeft: "auto",
            marginRight: "auto",
            color: "#000",
            textAlign: "center",
          }}
        >
          <small
            style={{ lineHeight: 1.8, fontSize: "2px", background: "#fff" }}
          >
            Powered by{" "}
            <a href="http://www.googlemapsgenerator.com/zh/">
              Googlemapsgenerator.com/zh/
            </a>{" "}
            & <a href="https://xn--mikroln-jxa.com/">mikrolån utan UC</a>
          </small>
        </div>
      </div>
      <br />
    </Box>
  );
}

export default Map;
