import React from "react";
import "./headerSlideA.css";

import img1 from "../images/img15Slide.jpeg";
import img2 from "../images/img2Slide.jpg";
import img3 from "../images/img3Slide.jpg";
import img4 from "../images/img16Slide.jpeg";
import img5 from "../images/img12Slide.jpeg";
import img6 from "../images/img6Slide.jpg";
import img7 from "../images/img7Slide.png";
import img8 from "../images/img8Slide.jpg";
import img9 from "../images/img14Slide.jpeg";
// import img4 from "../images/bestShowcaseBack2.png";
import NewMovePro from "./NewMovePro";
import { Carousel } from "antd";
import { Box } from "@mui/material";
// import { Stack } from "@mui/material";
const boxStyle = {
  marginTop: "100px",
  height: { xs: "300px", sm: "800px" },
  background: "white",
  // border: "1px solid black",
  position: "relative",
  display: "flex",
  // flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  gap: "20px",
};

const imgStyle = {
  // marginTop: "130px",
  width: { xs: "90%", sm: "1000px" },
  height: { xs: "200px", sm: "650px" },
  objectFit: { xs: "fill", sm: "fill" },
  // border: "1px solid blue",
  background: { xs: "none", sm: "rgba(107, 97, 97, 0.5)" },
  padding: "10px",
  flex: 1,
};

function HeaderSlideA() {
  return (
    <Box
      sx={{
        width: "100%",
        height: { xs: "100%", sm: "600px" },
        padding: { xs: "30px", sm: "0px" },
        marginTop: { xs: "50px", sm: "90px" },
        // border: "1px solid black",
      }}
    >
      <Box
        className="banner4 slide4"
        sx={{ marginTop: { xs: "150px", sm: "200px" } }}
      >
        <Carousel autoplay>
          <Box component="img" src={img1} alt="" sx={imgStyle} />

          <Box component="img" src={img2} alt="" sx={imgStyle} />

          <Box component="img" src={img3} alt="" sx={imgStyle} />

          <Box component="img" src={img4} alt="" sx={imgStyle} />

          <Box component="img" src={img5} alt="" sx={imgStyle} />

          <Box component="img" src={img6} alt="" sx={imgStyle} />

          <Box component="img" src={img7} alt="" sx={imgStyle} />

          <Box component="img" src={img8} alt="" sx={imgStyle} />
          <Box component="img" src={img9} alt="" sx={imgStyle} />
        </Carousel>
      </Box>
      <Box sx={{ marginTop: { xs: "70px", sm: "260px" } }}>
        <NewMovePro />
      </Box>
    </Box>
  );
}

export default HeaderSlideA;