import { Box, Button, Typography } from "@mui/material";
import { message } from "antd";
import React, { useContext, useState } from "react";
import { AddCart } from "../contexAPI/action";
import { Context } from "../contexAPI/stateProvider";

function NewMoveProSingle({ product }) {
  const { dispatch, cart } = useContext(Context);
  const [quantity, setQuantity] = useState(1);

  //handle method
  const handleAddCart = () => {
    const newCart = {
      id: product.id,
      title: product.title,
      img: product.img,
      isNew: product.isNew,
      des: product.desc,
      status: product.status,
      quantity: quantity,
      price: product.price,
    };
    try {
      const isItem = cart.find((item) => item.id === product.id);
      if (isItem) {
        isItem.quantity += quantity;
        message.info(
          "you are adding the quantity of already exited item,  go to the cart to control the quantity"
        );
      } else {
        dispatch(AddCart(newCart)); // distrust to protect the iteration problem
        message.success(`you added an item to cart successfully`);
      }
    } catch (error) {}
    // console.log(state);
  };

  return (
    <Box>
      <Box
        key={product.id}
        style={{
          position: "relative",
          // border: "1px solid black",
          // background: "#BFE3E6",
        }}
        className="product_container"
      >
        <Typography
          variant="h8"
          sx={{
            position: "absolute",
            bottom: 60,
            left: 10,

            background: "#BFE3E6",
            padding: "5px",
            borderRadius: "10px",
            color: "blue",
          }}
        >
          NEW
        </Typography>
        <Box
          component="img"
          src={product.img}
          alt="new products"
          // sx={{ width: { xs: "100px", sm: "100%" } }}
        />
        <div className="overlay">
          <Button
            sx={{
              color: "white",
              backgroundColor: "rgba(2, 0, 0, 0.4)",
              fontSize: "14px",
            }}
          >
            {product.title}
          </Button>
          <Button
            sx={{
              position: "absolute",
              top: 0,
              right: 20,
              zIndex: 2,
              background: "#EFE1CE",
              p: 2,
              width: "70px",
              height: "55px",
              color: "black",
              fontSize: 10,
              textAlign: "center",
              fontFamily: "Georgia",
              "&:hover": {
                backgroundColor: "#EFE1CE",
                fontWeight: "bold",
                boxShadow: "none",
              },
            }}
            onClick={handleAddCart}
          >
            ADD TO CART
          </Button>
        </div>
      </Box>
    </Box>
  );
}

export default NewMoveProSingle;
