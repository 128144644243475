import { Box, Stack } from "@mui/material";
import React from "react";
import AboutUs from "../components/AboutUs";
import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
// import WorkerSlideImg from "../components/WorkerSlideImg";

function AboutUsPg() {
  return (
    <Box sx={{ width: "100%" }}>
      <Stack spacing={2}>
        <Announcement />
        <Navbar />
        {/* <WorkerSlideImg /> */}
        <AboutUs />

        <Footer />
      </Stack>
    </Box>
  );
}

export default AboutUsPg;
