import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
// import img1 from "../images/desktop.jpg";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useParams } from "react-router-dom";
import { Context } from "../contexAPI/stateProvider";
// import { ADD_TO_CART } from "../contexAPI/constant";
import { AddCart } from "../contexAPI/action";
import { Image, message, Tooltip } from "antd";

function ProductScreen() {
  // states from context
  const { products, cart, dispatch } = useContext(Context);
  const [quantity, setQuantity] = useState(1);

  // specify product by param id
  const product = products[useParams().id - 1];

  const [selectImg, setSelectImg] = useState("img");
  console.log(selectImg);

  const IMG_PATH = product?.img;
  const IMG_PATH_1 = product?.imgOne;
  const IMG_PATH_2 = product?.imgTwo;
  const IMG_SELECT = product?.[selectImg];

  //handle method
  const handleAddCart = () => {
    const newCart = {
      id: product.id,
      title: product.title,
      img: product.img,
      isNew: product.isNew,
      des: product.desc,
      status: product.status,
      quantity: quantity,
      price: product.price,
    };
    try {
      const isItem = cart.find((item) => item.id === product.id);
      // if (isItem) isItem.quantity += quantity;
      if (isItem) {
        isItem.quantity += quantity;
        message.info(
          "you are adding the quantity of already exited item, go to the cart to control the quantity"
        );
      } else {
        dispatch(AddCart(newCart)); // distrust to protect the iteration problem
        message.success(`you added an item to cart successfully`);
      }
    } catch (error) {}
    // console.log(state);
  };
  //text style
  const textStyle = {
    fontFamily: "Georgia",
    color: "blue",
    border: "1px solid #BFE3E6",
    padding: "10px",
    borderRadius: "10px",
    fontSize: { xs: "14px", sm: "20px" },
  };

  return (
    <Box sx={{ minHeight: { xs: "500px", sm: "600px" }, p: { xs: 1, sm: 3 } }}>
      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          // background: "white",
          color: "blue",
          fontFamily: "Georgia",
          fontSize: { xs: "20px", sm: "35px" },
        }}
      >
        Single Product Screen
      </Typography>
      <Stack
        direction="row"
        gap={4}
        sx={{
          p: { xs: 1, sm: 5 },
          flexWrap: "wrap-reverse",
          // marginTop: "10px",
        }}
      >
        <Box
          sx={{
            height: "90%",
            display: "flex",
            flexDirection: "row",
            flex: 6,
            flexWrap: "wrap",

            // border: "1px solid #24CCD8",
          }}
        >
          <Stack
            direction="column"
            sx={{
              flex: 3,
              flexWrap: "wrap",
              flexDirection: { xs: "row", sm: "column" },
              // border: "1px solid black",
            }}
            gap={2}
          >
            <Tooltip
              placement="top"
              title={"click here to see the  product on the big screen"}
              color="blue"
            >
              <img
                src={IMG_PATH}
                alt=""
                style={{
                  flex: 8,
                  height: "60px",
                  maxWidth: "70px",
                  border: "1px solid #24CCD8",
                  cursor: "pointer",
                  // boxShadow: "-2px 2px 8px 0px rgba(0,0,0,0.75)",
                }}
                onClick={() => setSelectImg("img")}
              />
            </Tooltip>
            <Tooltip
              placement="top"
              title={"click here to see the big product on the big screen"}
              color="blue"
            >
              <img
                src={IMG_PATH_1}
                alt=""
                style={{
                  flex: 8,
                  height: "60px",
                  maxWidth: "70px",
                  border: "1px solid #24CCD8",
                  cursor: "pointer",
                  // boxShadow: "-2px 2px 8px 0px rgba(0,0,0,0.75)",
                }}
                onClick={() => setSelectImg("imgOne")}
              />
            </Tooltip>
            <Tooltip
              placement="top"
              title={"click here to see the  product on the big screen"}
              color="blue"
            >
              <img
                src={IMG_PATH_2}
                alt=""
                style={{
                  flex: 8,
                  height: "60px",
                  maxWidth: "70px",
                  border: "1px solid #24CCD8",
                  cursor: "pointer",
                  // boxShadow: "-2px 2px 8px 0px rgba(0,0,0,0.75)",
                }}
                onClick={() => setSelectImg("imgTwo")}
              />
            </Tooltip>
          </Stack>
          <Image
            src={IMG_SELECT}
            alt=""
            style={{
              flex: 8,
              height: "400px",
              width: "400px",
              display: { xs: "none", sm: "block" },
              background: "white",
              // border: "1px solid black",
              // boxShadow: "-2px 2px 8px 0px rgba(0,0,0,0.75)",
            }}
          />
        </Box>
        <Stack
          direction="column"
          gap={2}
          sx={{
            flex: 6,
            p: 4,
            // border: "1px solid black",
            width: { xs: "100%" },
            marginTop: { xs: "50px" },
          }}
        >
          <Typography variant="h4" sx={textStyle}>
            <span style={{ background: "#BFE3E6", padding: "5px" }}>
              Product Name:
            </span>
            {product?.title}
          </Typography>
          <Typography variant="h5" sx={textStyle}>
            <span style={{ background: "#BFE3E6", padding: "5px" }}>
              Model Name/ Brand Name:
            </span>
            {product?.modelName}/ {product?.brandName}
          </Typography>
          <Typography variant="h6" sx={textStyle}>
            <span style={{ background: "#BFE3E6", padding: "5px" }}>
              Description:
            </span>{" "}
            {product?.desc}
          </Typography>
          <Typography variant="h6" sx={textStyle}>
            <span style={{ background: "#BFE3E6", padding: "5px" }}>
              Price = ETB:
            </span>{" "}
            {product.price !== null
              ? new Intl.NumberFormat().format(product?.price)
              : "Not Available"}
          </Typography>
          <Stack direction="row" gap={4} sx={{ alignItems: "center" }}>
            {/* <IconButton onClick={() => setQuantity((prev) => prev + 1)}>
              <Tooltip
                placement="bottom"
                title={`add item quantity`}
                color="blue"
                style={{ color: "black" }}
              >
                <AddCircleIcon sx={{ fontSize: 50 }} />
              </Tooltip>
            </IconButton>
            <Typography variant="h4" sx={textStyle}>
              {quantity}
            </Typography>
            <IconButton
              onClick={() => quantity >= 2 && setQuantity((prev) => prev - 1)}
            >
              <Tooltip
                placement="bottom"
                title={`minus item quantity`}
                color="blue"
                style={{ color: "black" }}
              >
                <RemoveCircleIcon sx={{ fontSize: 50 }} />
              </Tooltip>
            </IconButton> */}
            <Box sx={{ border: "1px solid #BFE3E6", p: 1 }}>
              <Tooltip
                placement="bottom"
                title={"add this product to your items cart"}
                color="blue"
              >
                {product.price !== null && (
                  <Button
                    sx={{ border: "1px solid #BFE3E6" }}
                    onClick={handleAddCart}
                  >
                    ADD TO CART
                  </Button>
                )}
              </Tooltip>

              {product.pdfUrl !== null && (
                <Tooltip
                  placement="bottom"
                  title={
                    "review or download the detail SPEC PDF file of this product"
                  }
                  color="blue"
                >
                  <a href={product?.pdfUrl} target="_blank" rel="noreferrer">
                    <Button sx={{ border: "1px solid #BFE3E6" }}>
                      <PictureAsPdfIcon />
                    </Button>{" "}
                  </a>
                </Tooltip>
              )}
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

export default ProductScreen;
