import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  Alert,
} from "@mui/material";
import React, { useContext, useState } from "react";
import {
  addQuantity,
  createOrder,
  minusQuantity,
  // getAllOrder,
  RemoveAllCarts,
  RemoveCart,
} from "../contexAPI/action";
import { Context } from "../contexAPI/stateProvider";
import DeleteIcon from "@mui/icons-material/Delete";
import { Tooltip, message } from "antd";
import { useNavigate } from "react-router-dom";
import emptyCartImg from "../images/emptyCart.webp";
import { Modal, Space } from "antd";
import OrderDetail from "./OrderDetail";
import moment from "moment";
// import { collection, addDoc } from "firebase/firestore";
// import { db } from "../firebase";
import SubmitForm from "./SubmitForm";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

function Cart() {
  const { cart, dispatch, user, userDetail } = useContext(Context);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  // modal config
  const [open, setOpen] = useState(false);
  const [openFormList, setOpenFormList] = useState(false);
  const [openOrderDetail, setOpenOrderDetail] = useState(false);
  const [closeFormBtn, setCloseFormBtn] = useState(false);
  // const [quantity, setQuantity] = useState(1);
  // const navigate = useNavigate();

  //find total price
  const findTotal = () => {
    let total = 0;
    cart.forEach((item) => (total += item.quantity * item.price));
    return total.toFixed(2);
  };

  // generate unique id for cart
  const uniqueId = (length = 16) => {
    return parseInt(
      Math.ceil(Math.random() * Date.now())
        .toPrecision(length)
        .toString()
        .replace(".", "")
    );
  };

  const uId = uniqueId();

  const totalPrice = findTotal();

  // handle delete
  const handleDeleteItem = (id) => {
    dispatch(RemoveCart(id));
  };

  // do time out function
  const doTimeOut = () => {
    return setTimeout(() => {
      setError(null);
      setSuccess(null);
    }, 2000);
  };

  // handle fill form list and submit
  const handleCreateFormList = () => {
    // console.log("form created");
    setOpenFormList(true);
    setOpen(true);
    setCloseFormBtn(true);
  };

  // handle order and submit to the database
  const handleCreateOrder = async () => {
    // dispatch(doOpenFormList());

    setOpenOrderDetail(true);
    setOpenFormList(false);

    const newOrder = {
      userId: user.uid + uId || "xxxxxxxxxx",
      email: user.email || "xxxxxxxx@xxxxx.com",
      userName: userDetail?.name || "xxxxxxxxx",
      userAddress: userDetail?.address || "xxxxxxxxx",
      userPhoneNumber: userDetail?.phoneNumber || "xxxxxxxxx",
      userVisaAccount: userDetail?.visaAccount || "xxxxxxxxx",
      userPaymentMethod: userDetail?.paymentMethod || "xxxxxxxxx",

      orderDate: moment().format("MMMM Do YYYY, h:mm:ss a"),
      products: cart.map((item) => ({
        productId: item.id,
        productDesc: item.desc,
        productImg: item.img,
        productTitle: item.title,
        productQuantity: item.quantity,
        productStatus: item.status,
        productPrice: item.price,
      })),
      totalAmount: totalPrice,
    };
    try {
      dispatch(createOrder(newOrder));
      console.log(newOrder);
      setSuccess("well, you have submitted your items order successfully");
      doTimeOut();
      setOpen(true);
      dispatch(RemoveAllCarts());

      message.success(
        ` ${userDetail.name} you have done successfully, now enjoy our other products!`
      );
    } catch (error) {
      setError(error + "Something went wrong!");
      doTimeOut();
      console.log(error);
    }
  };

  const textStyle = {
    fontFamily: "Georgia",
    color: "blue",
    fontSize: { xs: "10px", sm: "16px" },
  };

  const handleOk = () => {
    userDetail && setOpen(false);
  };

  const hideModal = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        width: "100%",

        p: 3,
        // border: "1px solid red",
      }}
    >
      <Stack
        spacing={2}
        direction="column"
        sx={{ height: "100%", marginTop: "50px" }}
      >
        <Typography
          variant="h3"
          sx={{
            textAlign: "center",
            fontFamily: "Georgia",
            color: "blue",
            fontSize: { xs: "16px", sm: "35px" },
          }}
        >
          YOUR CART ITEM LISTS
        </Typography>
        <Stack
          spacing={2}
          direction="row"
          gap={3}
          sx={{ flexWrap: "wrap", width: { xs: "100%", sm: "100%" } }}
        >
          {cart.length >= 1 ? (
            <Stack
              direction="column"
              sx={{
                flex: 1,
                p: 3,
                // height: "120px",
                width: "100%",
                flexWrap: "wrap",
              }}
              gap={3}
              key={cart.id}
            >
              {cart.map((item) => (
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  sx={{
                    flex: { xs: 1, sm: 2 },

                    borderRadius: "10px",
                    background: "#f2e6e6",
                    // width: { xs: "100%", sm: "100%" },
                    height: { xs: "300px", sm: "100%" },
                    // border: "1px solid black",

                    // boxShadow:
                    //   "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
                  }}
                  gap={{ xs: 1, sm: 4 }}
                  key={item.id}
                >
                  <img
                    width={100}
                    src={item.img}
                    alt=""
                    style={{
                      flex: 1,
                      height: "200px",
                      width: "100px",
                      // border: "1px solid black",
                    }}
                  />
                  <Stack
                    sx={{
                      flex: { xs: 2, sm: 1 },
                      p: { xs: 1, sm: 2 },
                      // border: "1px solid black",
                      height: { xs: "300px", sm: "300px" },
                      background: "white",
                    }}
                    gap={{ xs: 1, sm: 2 }}
                  >
                    <Typography sx={textStyle}>
                      NAME: <strong>{item.title}</strong>
                    </Typography>
                    <Typography sx={textStyle}>
                      STATUS: <strong> {item.status}</strong>
                    </Typography>

                    <Stack
                      position="row"
                      sx={{
                        justifyContent: "space-between",
                        // alignItems: "center",
                      }}
                    >
                      <IconButton
                        onClick={() => dispatch(addQuantity(item.id))}
                      >
                        <Tooltip
                          placement="bottom"
                          title={`add item quantity`}
                          color="blue"
                          style={{ color: "black" }}
                        >
                          <AddCircleIcon sx={{ color: "blue" }} />
                        </Tooltip>
                      </IconButton>
                      <Typography sx={textStyle}>
                        QUANTITY:{item.quantity}
                      </Typography>
                      <IconButton
                        onClick={() =>
                          item.quantity >= 2 && dispatch(minusQuantity(item.id))
                        }
                      >
                        <Tooltip
                          placement="bottom"
                          title={`minus item quantity`}
                          color="blue"
                          style={{ color: "black" }}
                        >
                          <RemoveCircleIcon sx={{ color: "blue" }} />
                        </Tooltip>
                      </IconButton>
                    </Stack>

                    <Typography sx={textStyle}>
                      PRICE:<strong> ETB {item.price}</strong>
                    </Typography>
                  </Stack>

                  <Tooltip
                    placement="bottom"
                    title={"delete item from cart"}
                    color="red"
                    style={{ color: "black" }}
                  >
                    <IconButton
                      sx={{ height: "50px" }}
                      color="error"
                      onClick={() => handleDeleteItem(item.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              ))}
            </Stack>
          ) : (
            <Box
              sx={{
                flex: 1,
                background: "#f1f2ed",
                flexDirection: "column",

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "blue",
                  fontFamily: "Georgia",
                }}
              >
                sorry, you don't have any item in the cart
              </Typography>
              <img src={emptyCartImg} alt="no product found img" width="100%" />
            </Box>
          )}

          {cart.length >= 1 ? (
            <Box sx={{ flex: 1, p: 3 }}>
              <Stack
                gap={{ xs: 2, sm: 4 }}
                sx={{
                  flex: 1,
                  // border: "1px solid #BFE3E6",
                  // border: "1px solid black",
                  height: "400px",
                  width: "100%",
                  // marginLeft: "150px",
                  flexDirection: "column",
                  background: "white",
                  p: 2,
                  // boxShadow:
                  //   "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
                }}
              >
                <Typography sx={textStyle}>ORDER SUMMERY</Typography>
                <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                  <Typography sx={textStyle}>SUBTOTAL</Typography>
                  <Typography sx={textStyle}>ETB {totalPrice} + 20</Typography>
                </Stack>
                <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                  <Typography sx={textStyle}>DISCOUNT</Typography>
                  <Typography sx={textStyle}>ETB 20 </Typography>
                </Stack>
                <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                  <Typography sx={textStyle}>TOTAL</Typography>
                  <Typography
                    sx={
                      (textStyle,
                      { background: "#f2e6e6", p: { xs: 0, sm: 1 } })
                    }
                  >
                    ETB {totalPrice}
                  </Typography>
                </Stack>

                {/* handle form lists */}
                {cart.length >= 1 ? (
                  <Button
                    disabled={userDetail}
                    sx={{
                      border: "1px solid #BFE3E6",
                      fontSize: { xs: "10px", sm: "15px" },
                      "&:hover": {
                        backgroundColor: "blue",
                        boxShadow: "none",
                        color: "white",
                      },
                    }}
                    onClick={handleCreateFormList}
                  >
                    CONTINUE YOUR PAYMENT
                  </Button>
                ) : (
                  <Tooltip
                    placement="bottom"
                    title={
                      "please, you should have at least one item in cart for your order products"
                    }
                    color="red"
                    style={{ color: "black" }}
                  >
                    <Button
                      sx={{
                        border: "1px solid #BFE3E6",
                        color: "#c1cad9",
                        cursor: "not-allowed",
                        "&:hover": {
                          backgroundColor: "blue",
                          color: "white",
                        },
                      }}
                    >
                      PROCEED VERIFY
                    </Button>
                  </Tooltip>
                )}

                {/* handle create order detail */}
                {cart.length >= 1 ? (
                  <Button
                    disabled={!userDetail}
                    sx={{
                      border: "1px solid #BFE3E6",
                      fontSize: { xs: "10px", sm: "15px" },
                    }}
                    onClick={handleCreateOrder}
                  >
                    PROCEED VERIFY
                  </Button>
                ) : (
                  <Tooltip
                    placement="bottom"
                    title={
                      "please, you should have at least one item in cart for your order products"
                    }
                    color="red"
                    style={{ color: "black" }}
                  >
                    <Button
                      sx={{
                        border: "1px solid #BFE3E6",
                        color: "#c1cad9",
                        cursor: "not-allowed",
                        fontSize: { xs: "10px", sm: "20px" },
                        "&:hover": {
                          backgroundColor: "blue",
                          color: "white",
                        },
                      }}
                    >
                      PROCEED VERIFY
                    </Button>
                  </Tooltip>
                )}

                {error && <Alert severity="error">{error}</Alert>}
                {success && <Alert severity="success">{success}</Alert>}
              </Stack>
            </Box>
          ) : (
            <Typography></Typography>
          )}
        </Stack>

        {/* getting all detail order information modal*/}
        {openOrderDetail && (
          <Stack>
            <Modal
              title="Thank you for your shopping with us. Look at your submitted detail user and order lists"
              open={open}
              onOk={handleOk}
              onCancel={handleOk}
              okText="."
              cancelText="FINISH"
              okButtonProps={{ disabled: true }}
              cancelButtonProps={{ disabled: !userDetail ? true : false }}
              width={1000}
            >
              <OrderDetail />
            </Modal>
          </Stack>
        )}
        {/* getting form list modal */}
        {openFormList && (
          <Stack>
            <Modal
              title="Please fill this form and get your product's detail lists. finally click `finish` button"
              open={open}
              onOk={handleOk}
              onCancel={hideModal}
              okText="."
              cancelText="FINISH"
              okButtonProps={{ disabled: true }}
              cancelButtonProps={{ disabled: !userDetail ? true : false }}
              width={500}
            >
              <SubmitForm />
            </Modal>
          </Stack>
        )}
      </Stack>
    </Box>
  );
}

export default Cart;
