import { Button } from "@mui/material";
import React, { useContext } from "react";
import { Context } from "../contexAPI/stateProvider";
import "./movePro.css";
import NewMoveProSingle from "./NewMoveProSingle";

function NewMovePro() {
  // states from context
  const { products } = useContext(Context);

  const newProducts = products.filter((product) => product.isNew === true);

  console.log(newProducts);

  return (
    <div className="wrapper">
      <div className="photobanner" style={{ position: "relative" }}>
        {newProducts.map((product) => {
          return <NewMoveProSingle key={product.id} product={product} />;
        })}
      </div>
    </div>
  );
}

export default NewMovePro;
