import { Box, Stack, styled, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { aboutData } from "../dommyData/aboutData";
import AOS from "aos";
import "aos/dist/aos.css";

function AboutUs() {
  const MyMainStack = styled(Stack)(({ theme }) => ({
    minHeight: "300px",

    display: "flex",
    flexWrap: "wrap",
    flexDirection: { xs: "row", sm: "row" },
    justifyContent: "space-between",
    gap: "20px",

    "&:nth-child(odd)": {
      flexDirection: "row-reverse",
    },
    "&:nth-child(even)": {
      flexDirection: "row",
    },
  }));

  // used for box animate with scroll
  useEffect(() => {
    AOS.init({
      duration: 600,
    });
  }, []);

  // text style
  const titleStyle = {
    color: "blue",
    fontFamily: "Georgia",
    fontSize: { xs: "17px", sm: "25px" },
    background: "#BFE3E6",
    p: 1,
  };

  const descStyle = {
    color: "blue",
    fontFamily: "Georgia",
    fontSize: { xs: "12px", sm: "25px" },
    width: { xs: "100%", sm: "100%" },
    height: { xs: "auto", sm: "%" },
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        gap: 13,
        p: 5,
      }}
    >
      <Typography
        variant="h2"
        sx={{
          textAlign: "center",
          background: "white",
          color: "blue",
          fontFamily: "Georgia",
          marginTop: "50px",
          fontSize: { xs: "20px", sm: "35px" },
        }}
      >
        Company General Profile
      </Typography>
      {aboutData.map((list) => {
        return (
          <MyMainStack
            key={list.id}
            sx={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}
          >
            <Stack sx={{ flex: 1 }} data-aos="flip-left">
              {" "}
              <Box
                component="img"
                src={list.image}
                alt=""
                sx={{
                  width: { xs: "300px", sm: "600px" },
                  height: { xs: "300px", sm: "500px" },
                }}
              />
            </Stack>
            <Stack
              sx={{ flex: 2, background: "white", p: 4 }}
              data-aos="zoom-in-down"
            >
              <Typography variant="h5" sx={titleStyle}>
                {list.title}
              </Typography>
              <Typography variant="h6" sx={descStyle}>
                {list.desc}
              </Typography>
            </Stack>
          </MyMainStack>
        );
      })}
    </Box>
  );
}

export default AboutUs;
