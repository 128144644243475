import * as React from "react";
import AppBar from "@mui/material/AppBar";
// import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, Badge, Box, Stack, styled } from "@mui/material";
// import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Link } from "react-router-dom";
import { Context } from "../contexAPI/stateProvider";
import { LogOut } from "../contexAPI/action";
import { Dropdown, message, Tooltip } from "antd";
import startLogo from "../images/newStart1.png";
import MenuIcon from "@mui/icons-material/Menu";
import ClearIcon from "@mui/icons-material/Clear";
import "animate.css";

export default function ButtonAppBar() {
  const MyToolbar = styled(Box)(({ theme }) => ({
    // position: "sticky",
    width: "100%",
    // marginTop: "20px",
    background: "#f2e6e6",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "absolute",
    // top: 0,
    flexDirection: "row",
    // height: "auto",
    // border: "1px solid red",
    [theme.breakpoints.down("md")]: {
      // flexDirection: "row",
    },
  }));

  const MyMainBarStack = styled(Stack)(({ theme }) => ({
    width: "100%",
    // height: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    gap: "20px",

    // border: "1px solid black",
    [theme.breakpoints.down("md")]: {
      height: "20px",
    },
  }));

  const MyMainMenuStack = styled(Stack)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",

    // border: "1px solid black",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  }));

  const MyAccountMenuStack = styled(Stack)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",

    // border: "1px solid black",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  }));

  const MyMobileScreenStack = styled(Stack)(({ theme }) => ({
    display: "none",

    // border: "1px solid black",
    [theme.breakpoints.down("md")]: {
      // display: "block",
      textAlign: "center",
      display: "flex",
      alignItems: "end",
      justifyContent: "end",
      // border: "1px solid black",
    },
  }));

  // down menu for mobile screen
  const DownMenuStack = styled(Stack)(({ theme }) => ({
    zIndex: 999,
    width: "100%",
    height: "450px",
    background: "#f2e6e6",
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    gap: 2,
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    padding: "5px",
    boxShadow: "-1px -1px 10px 3px rgba(219,190,190,0.75)",
    borderRadius: "5px",
    top: 0,
    right: 0,
    // border: "1px solid black",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  }));

  // remove underline style
  const style = { textDecoration: "none" };
  // navbar style

  // states from context
  const { user, dispatch, cart, userDetail } = React.useContext(Context);

  // handle log out
  const handleLogOut = () => {
    dispatch(LogOut()); //can nullify user and cart as well
    message.warning(
      `Dear! you have logged out, but you don't have access to see the cart item`
    );
  };

  // responsive state
  const [openDownMenu, setOpenDownMenu] = React.useState(false);

  // down menu
  const items = [
    {
      key: "1",
      label: (
        <Tooltip
          placement="right"
          title={"Click to see all products"}
          color="#108ee9"
          style={{ color: "black" }}
        >
          <Link to="/Products" style={style}>
            <Button variant="outlined" sx={{ width: "100%" }} href="#Partner">
              Product Lists
            </Button>
          </Link>
        </Tooltip>
      ),
    },
    {
      key: "2",
      label: (
        <Tooltip
          placement="right"
          title={"Click to see products with category"}
          color="#108ee9"
          style={{ color: "black" }}
        >
          <Link to="/Categories" style={style}>
            <Button variant="outlined" sx={{ width: "100%" }}>
              Category Lists
            </Button>
          </Link>
        </Tooltip>
      ),
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        zIndex: 999,
        position: "sticky",
        height: "80px",
        // border: "1px solid black",
        // position: "absolute",
        top: 0,
      }}
    >
      <Box
        sx={{
          // background: "#f2e6e6",
          color: "black",
          minHeight: "100px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <MyToolbar>
          {/* main navbar component */}
          <MyMainBarStack>
            <Stack direction="row" gap={2}>
              <Link to="/" style={style}>
                <Box
                  component="img"
                  src={startLogo}
                  alt=""
                  sx={{
                    width: { xs: "150px", sm: "200px" },
                    // border: "1px solid black",
                    marginRight: { xs: "130px", sm: "0px" },
                  }}
                />
              </Link>
            </Stack>
            {/* main menu component */}
            <MyMainMenuStack gap={4}>
              <Tooltip
                placement="bottom"
                title={
                  "Click the home page to see our showcase, all listed products, and other features"
                }
                color="#108ee9"
                style={{ color: "black" }}
              >
                <Link to="/" style={style}>
                  <Button variant="outlined">HOME</Button>
                </Link>
              </Tooltip>

              <Dropdown
                menu={{
                  items,
                }}
                placement="bottom"
              >
                <Button variant="outlined">OUR PRODUCTS</Button>
              </Dropdown>

              <Tooltip
                placement="bottom"
                title={"Sorry, our blog is not active at the moment"}
                color="#108ee9"
                style={{ color: "black" }}
              >
                <Link style={style}>
                  <Button variant="outlined">BLOG</Button>
                </Link>
              </Tooltip>

              <Tooltip
                placement="bottom"
                title={
                  "Click to see our overall company background, vision and others"
                }
                color="#108ee9"
                style={{ color: "black" }}
              >
                <Link to="/AboutUs" style={style}>
                  <Button variant="outlined">ABOUT US</Button>
                </Link>
              </Tooltip>

              <Tooltip
                placement="bottom"
                title={"Click to contact us and get latest information"}
                color="#108ee9"
                style={{ color: "black" }}
              >
                <Link to="/ContactUs" style={style}>
                  <Button variant="outlined">Contact Us</Button>
                </Link>
              </Tooltip>
            </MyMainMenuStack>
            {/* account menu component */}
            <MyAccountMenuStack
              direction={{ xs: "column", md: "row" }}
              gap={2}
              // sx={{ border: "1px solid black" }}
            >
              <Link to="/Cart">
                <Tooltip
                  placement="bottom"
                  title={"cart item lists"}
                  color="#108ee9"
                  style={{ color: "black" }}
                >
                  <IconButton size="large" aria-label="show 4 new mails">
                    <Badge badgeContent={cart?.length} color="primary">
                      <ShoppingCartIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </Link>
              {!user && (
                <Tooltip
                  Tooltip
                  placement="bottom"
                  title={`Dear customer sign in, to be our  member and get access your cart lists`}
                  color="#108ee9"
                  style={{ color: "black" }}
                >
                  <Link to="/Login" style={style}>
                    <Button variant="outlined" sx={{ textDecoration: "none" }}>
                      Sign In
                    </Button>
                  </Link>
                </Tooltip>
              )}
              {!user && (
                <Tooltip
                  placement="bottom"
                  title={`Dear customer sign up if you are not registered yet`}
                  color="#108ee9"
                  style={{ color: "black" }}
                >
                  <Link to="/SignUp" style={style}>
                    <Button variant="outlined">Register</Button>
                  </Link>
                </Tooltip>
              )}
              {user && (
                <Tooltip
                  placement="bottom"
                  title={`If you are logged out, you will not have an access to see your cart list items. Your previous cart item list will be removed as well`}
                  color="#108ee9"
                  style={{ color: "black" }}
                >
                  <Button variant="outlined" onClick={handleLogOut}>
                    Sign Out
                  </Button>
                </Tooltip>
              )}
              <Avatar src="/broken-image.jpg" />
            </MyAccountMenuStack>
          </MyMainBarStack>

          {/* for mobile screen */}
          <MyMobileScreenStack>
            <IconButton onClick={() => setOpenDownMenu(true)}>
              <MenuIcon fontSize="large" />
            </IconButton>
          </MyMobileScreenStack>
          {openDownMenu && (
            <Box>
              <DownMenuStack className="animate__animated animate__bounceInLeft">
                <IconButton
                  onClick={() => setOpenDownMenu(false)}
                  sx={{ position: "absolute", right: 0 }}
                >
                  <ClearIcon fontSize="large" />
                </IconButton>
                <Link to="/" style={style}>
                  <Button
                    // variant="outlined"
                    sx={{ width: "100%" }}
                  >
                    HOME
                  </Button>
                </Link>

                <Dropdown
                  menu={{
                    items,
                  }}
                  placement="bottom"
                >
                  <Button sx={{ width: "100%" }}>OUR PRODUCTS</Button>
                </Dropdown>

                <Link style={style}>
                  <Button sx={{ width: "100%" }}>BLOG</Button>
                </Link>

                <Link to="/AboutUs" style={style}>
                  <Button sx={{ width: "100%" }}>ABOUT US</Button>
                </Link>

                <Link to="/ContactUs" style={style}>
                  <Button sx={{ width: "100%" }}>Contact Us</Button>
                </Link>
                <Link to="/Cart">
                  <Tooltip
                    placement="bottom"
                    title={"cart item lists"}
                    color="#108ee9"
                    style={{ color: "black" }}
                  >
                    <IconButton size="large" aria-label="show 4 new mails">
                      <Badge badgeContent={cart?.length} color="primary">
                        <ShoppingCartIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </Link>
                {!user && (
                  <Link to="/Login" style={style}>
                    <Button sx={{ textDecoration: "none" }}>Sign In</Button>
                  </Link>
                )}
                {!user && (
                  <Link to="/SignUp" style={style}>
                    <Button>Register</Button>
                  </Link>
                )}
                {user && <Button onClick={handleLogOut}>Sign Out</Button>}
                <Avatar src="/broken-image.jpg" />
              </DownMenuStack>
            </Box>
          )}
        </MyToolbar>
      </Box>
    </Box>
  );
}
