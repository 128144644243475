import { async } from "@firebase/util";
import { Box } from "@mui/material";
import { addDoc, collection } from "firebase/firestore";
import React, { useContext, useState } from "react";
import "../components/submitForm.css";
import {
  addUserDetail,
  // doCloseFormList,
  // doOpenFormList,
} from "../contexAPI/action";
import { Context } from "../contexAPI/stateProvider";
import { db } from "../firebase";
// import mobileTransfer from "../images/mobile1.png";
// import tellBirr from "../images/tellBirr1.png";
// import visa from "../images/visa1.png";

function SubmitForm() {
  const { dispatch } = useContext(Context);
  const [isSubmitted, setIsSubmitted] = useState(false);
  // form data state
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    phoneNumber: "",
    paymentMethod: "mobileBanking",
    visaAccount: "",
  });

  console.log(formData);
  console.log(Date());
  const user_info = `user_info_${formData.name}_${Date()}`;

  // handle form change
  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const { name, address, phoneNumber, paymentMethod, visaAccount } = formData;
  //
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("form submit");
    setIsSubmitted(true);
    // Add a new document with a generated id.
    const res = await addDoc(collection(db, user_info), formData);
    console.log(res);
    // add this user address detail
    dispatch(addUserDetail(formData));
    // dispatch(doCloseFormList());
  };
  return (
    <Box sx={{ border: "1px solid black" }}>
      <div className="container">
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="info">
            <label>Name:</label>
            <div>
              <input
                type="text"
                name="name"
                value={name}
                placeholder="Enter Username"
                required
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="info">
            <label>Address:</label>
            <div>
              <input
                type="text"
                name="address"
                value={address}
                placeholder="E.g, Addis Ababa, bole, .."
                required
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="info">
            <label htmlFor="phone">Phone No</label>
            <div>
              <input
                id="phone"
                type="tel"
                name="phoneNumber"
                placeholder="+2519457862"
                required
                value={phoneNumber}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="info">
            <div>
              <label htmlFor="cars">Choose Payment Method</label>
            </div>
            <div>
              <select
                name="paymentMethod"
                value={paymentMethod}
                id="payment_method"
                onChange={handleChange}
              >
                <option value="mobileBanking">Mobile_Banking</option>
                <option value="teleBirr">Tele_Birr</option>
                <option value="visa">Visa</option>
              </select>
            </div>
          </div>
          <div className="info">
            <label>Your Bank Acc.NO</label>
            <div>
              <input
                type="text"
                name="visaAccount"
                value={visaAccount}
                placeholder="Enter Account No"
                required
                onChange={handleChange}
              />
            </div>
          </div>
          {!isSubmitted ? (
            <button type="submit" value="Submit" className="btn">
              Submit
            </button>
          ) : (
            <button type="submit" value="Submit" className="btn" disabled>
              you have submitted, now close this form
            </button>
          )}
        </form>
      </div>
    </Box>
  );
}

export default SubmitForm;
